import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import igse from '../../assets/curriculum/cambridge.jpg'
import british from '../../assets/curriculum/pearson.jpg'
import kicd from '../../assets/curriculum/kicd.jpg'
import oxford from '../../assets/curriculum/Ki12.jpg'
import knec from '../../assets/curriculum/knec.jpg'
import ib from '../../assets/curriculum/ib.jpg'

import './course.css'

function Course() {
    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        centerMode: true,
        margin: "0 50px", 
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "20px",
                    margin: "0 10px",
                },
            },
        ],
    };
    return (
        <>
            <div className="container logo-slider">
                <Slider {...settings}>
                    <img src={igse} alt="igse curriculum" />
                    <img src={british} alt="british curriculum" />
                    <img src={oxford} alt="oxford curriculum" />
                    <img src={kicd} alt="kicd curriculum" />
                    <img src={knec} alt="knec curriculum" />
                    <img src={ib} alt="ib curriculum" />
                </Slider>
            </div>
        </> 
    );
}

export default Course;
