import andy from './assets/teachers/andy.jpg'
import art from './assets/activities/creative-art.jpg'
import tour from './assets/activities/Virtual-Tour.jpg'
import carol from './assets/testimoniala/carol.jpeg'
import cristabel from './assets/testimoniala/christabel.jpeg'





export const whyUs = [{
    reason:'Experienced and passionate teaching staff.',
    description:"Our school boasts highly skilled teachers who are committed to helping your child excel academically and reach their full potential. Trust us with your child's education."
},
{
    reason:'Individualized learning plans for all',
    description:'At our school, we believe that every child is unique, which is why we offer individualized learning plans to meet the needs and goals of each student.'
},
{
  reason:'Diverse extracurricular activities available.',
  description:"Our school offers a wide range of diverse extracurricular activities to enrich your child's educational experience and help them explore their interests and passions outside the classroom."
}
]


 export const Teachers =[
    {
    name:'Alividza Aligula',
    position:'Administrator',
    subjects:' ',
    profile:andy
}
    
]
export const Activity = [
    {
        image:tour,
        name:'Virtual field trips',
        date:'November 19,2019',
        desc:'A virtual field trip is an online educational experience that allows homeschooling students to explore places of interest without leaving home. It can feature interactive activities, multimedia resources, and live presentations.'

    },
    {
        image:art,
        name:'Art Exhibition',
        date:'January 12,2012',
        desc:'An art exhibition attended by students is a display of artworks created by various artists, where students can observe and appreciate different art styles, techniques, and themes to inspire their creativity.'

    }
]

export const testimonial = [
    {
      id: 1,
      image:carol,
      name: 'Carol',
      title: 'office manager',
      quote:
      "Through Avana Homeschooling, I've gained profound, insightful knowledge about Germany's history, culture, and language"    },
    {
      id: 2,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg',
      name: 'Mark Tuchel',
      title: 'regular guy',
      quote:
      "Avana Homeschooling made our transition seamless with exceptional guidance and an adaptable curriculum. Forever grateful!"    },
    {
      id: 3,
      image:cristabel,
      name: 'Christabel Kanja',
      title: 'product designer',
      quote:
      "My kids are thriving with Avana Homeschooling's personalized attention and incredible resources. Highly recommended!"    },
    {
      id: 4,
      image:
        'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg',
      name: 'susan andersen',
      title: 'the boss',
      quote:
      "Avana Homeschooling's flexibility and support allowed my children to excel academically while nurturing their creativity. A perfect learning environment!"
    },
  ];
  