export let phoneSpecs = [
  "Laptop for online classes",
  "Whiteboard",
  "Diary (provided by the school)",
  "Books A4 size 120-200 pages",
  "Pens",
  "Pencils",
  "Ruler",
  "Geometrical set",
  "Eraser",
  "Sharpener",
  "Highlighters",
  "Drawing book provided by the school",
  "GraphBook",
  "Textbooks",
  "File (examination papers)",
  "Pencil or pen case",
  "Colors (reception to KS1)",
  "Dictionary from KS2",
  "Kamusi for students learning Swahili",
  "School bag",
  "School t-shirt provided by the school"
];

  export const laptopSpecs = [
    'Display: 12-14 inches, Full HD (1080p)',
    'CPU: Intel Core i5 or higher',
    'Memory: Minimum 8GB',
    'Storage: 256GB SSD or more (HDD also accepted)',
    'OS: Windows, macOS, Linux, or ChromeOS',
    'Battery: At least 6-hour duration',
    'Webcam: Required',
    'Audio: Quality built-in speakers',
    'Brightness: 50% default setting',
    'Connectivity: Wi-Fi, Bluetooth',
    'Ports: USB, HDMI, SD card slot',
    'Weight: Lightweight design'
  ];
  
  